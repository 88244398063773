import algoliasearch from 'algoliasearch';
import { useEnv } from './useEnv';

export const useAlgoliaClient = () => {
  const { env } = useEnv();
  const searchClient = algoliasearch(
    (env.ALGOLIA_APP_ID as string) ?? '',
    (env.ALGOLIA_SEARCH_API_KEY as string) ?? '',
  );

  return searchClient;
};
